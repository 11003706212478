<template>
  <div>
    <el-table id="exportTable" :data="putTableData" border style="width: 100%">
      <el-table-column prop="stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="mtrl_num" label="入库米数" sortable align="center"></el-table-column>
      <el-table-column prop="mtrl_value" label="样品款数" sortable align="center"></el-table-column>
      <el-table-column prop="mtrl_sal" label="产值金额" sortable align="center"></el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-show="false" id="exportTableAll" :data="allTableData" border style="width: 100%">
      <el-table-column prop="stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="mtrl_num" label="入库米数" sortable align="center"></el-table-column>
      <el-table-column prop="mtrl_value" label="样品款数" sortable align="center"></el-table-column>
      <el-table-column prop="mtrl_sal" label="产值金额" sortable align="center"></el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableData', 'allTableData'],
  name: 'Put',
  computed: {
    putTableData() {
      return this.tableData;
    }
  },
  methods: {
    toTimeDay(time) {
      return this.helper.toTimeDay(time);
    }
  }
};
</script>

<style scoped></style>
