<template>
  <div>
    <el-table id="exportTable" :data="stamTableData" border style="width: 100%">
      <el-table-column prop="stam.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="stam.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="stam.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="stam.rfid_stam_phr" label="印刷片数" sortable align="center"></el-table-column>
      <el-table-column prop="requ_num" label="印刷款数" sortable align="center"></el-table-column>
      <el-table-column prop="stam_value" label="产值金额" sortable align="center"></el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-show="false" id="exportTableAll" :data="stamTableData" border style="width: 100%">
      <el-table-column prop="stam.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="stam.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="stam.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="stam.rfid_stam_phr" label="印刷片数" sortable align="center"></el-table-column>
      <el-table-column prop="requ_num" label="印刷款数" sortable align="center"></el-table-column>
      <el-table-column prop="stam_value" label="产值金额" sortable align="center"></el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableData'],
  name: 'Stam',
  computed: {
    stamTableData() {
      return this.tableData;
    }
  },
  methods: {
    toTimeDay(time) {
      return this.helper.toTimeDay(time);
    }
  }
};
</script>

<style scoped></style>
