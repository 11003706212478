<template>
  <div>
    <el-table id="exportTable" :data="emcdTableData" border style="width: 100%">
      <el-table-column prop="emcd.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="emcd.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="emcd.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="emcd.rfid_emcd_phr" label="制卡针数" sortable align="center"></el-table-column>
      <el-table-column prop="requ_num" label="制卡款数" sortable align="center"></el-table-column>
      <el-table-column label="产值金额" sortable align="center">
        <template v-slot="scope">
          <span v-if="scope.row.smpl_type === 1">{{ scope.row.new_card_value }}</span>
          <span v-else>{{ scope.row.modify_card_value }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-show="false" id="exportTableAll" :data="allTableData" border style="width: 100%">
      <el-table-column prop="emcd.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="emcd.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="emcd.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="emcd.rfid_emcd_phr" label="制卡针数" sortable align="center"></el-table-column>
      <el-table-column prop="requ_num" label="制卡款数" sortable align="center"></el-table-column>
      <el-table-column label="产值金额" sortable align="center">
        <template v-slot="scope">
          <span v-if="scope.row.smpl_type === 1">{{ scope.row.new_card_value }}</span>
          <span v-else>{{ scope.row.modify_card_value }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableData', 'allTableData'],
  name: 'Emcd',
  computed: {
    emcdTableData() {
      return this.tableData;
    }
  },
  methods: {
    toTimeDay(time) {
      return this.helper.toTimeDay(time);
    }
  }
};
</script>

<style scoped></style>
