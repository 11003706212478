<template>
  <div>
    <el-table id="exportTable" :data="manuTableData" border style="width: 100%">
      <el-table-column prop="manu.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="manu.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="manu.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manuStyleA" label="A级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleAVal" label="A级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleB" label="B级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleBVal" label="B级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleC" label="C级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleCVal" label="C级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleD" label="D级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleDVal" label="D级个数" sortable align="center"></el-table-column>
      <el-table-column prop="" label="产值金额" sortable align="center">
        <template v-slot="scope">
          <span>{{ scope.row.manuAValue + scope.row.manuBValue + scope.row.manuCValue + scope.row.manuDValue }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-show="false" id="exportTableAll" :data="allTableData" border style="width: 100%">
      <el-table-column prop="manu.stff_name" label="员工姓名" width="180" align="center"></el-table-column>
      <el-table-column prop="manu.dept_name" label="所属打样间" width="180" align="center"></el-table-column>
      <el-table-column prop="manu.user_jobs" label="岗位" align="center"></el-table-column>
      <el-table-column prop="smpl_type" label="样品类型" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manuStyleA" label="A级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleAVal" label="A级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleB" label="B级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleBVal" label="B级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleC" label="C级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleCVal" label="C级个数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleD" label="D级款数" sortable align="center"></el-table-column>
      <el-table-column prop="manuStyleDVal" label="D级个数" sortable align="center"></el-table-column>
      <el-table-column prop="" label="产值金额" sortable align="center">
        <template v-slot="scope">
          <span>{{ scope.row.manuAValue + scope.row.manuBValue + scope.row.manuCValue + scope.row.manuDValue }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="统计时间" align="center">
        <template v-slot="scope">
          <span>{{ toTimeDay(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['tableData', 'allTableData'],
  name: 'Manu',
  computed: {
    manuTableData() {
      return this.tableData;
    }
  },
  methods: {
    toTimeDay(time) {
      return this.helper.toTimeDay(time);
    }
  }
};
</script>

<style scoped></style>
