<template>
  <div class="vg_wrapper" v-loading="loading">
    <el-card>
      <el-row :gutter="60" style="margin-bottom: 20px">
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('open')">开版师</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('open')" :disabled="true">报价员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('put')">入库员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('stpr')">备料员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('mase')">机缝工</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('buyer')" :disabled="true">采购员</el-button></el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('lase')">激光员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('stam')">印花员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('emcd')">制卡员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('embr')">电绣员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('comp')">复合员</el-button></el-col>
        <el-col :span="4"><el-button class="jobsBorder" @click="changeModule('manu')">手工员</el-button></el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row>
        <el-col :span="24" style="margin-bottom: 20px">
          <div class="diagramTitle">
            <span style="font-size: 20px; margin-right: 10px">统计时间:</span>
            <div class="dateSelect">
              <el-date-picker
                v-model="daytime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <el-button round class="buttonText" size="mini" @click="timeScope(7)">近7天</el-button>
            <el-button round class="buttonText" size="mini" @click="timeScope(30)">近1个月</el-button>
            <el-button round class="buttonText" size="mini" @click="timeScope(180)">近6个月</el-button>
            <el-button round class="buttonText" size="mini" @click="timeScope(0)">全部</el-button>
          </div>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="100">
            <el-col :span="12">
              <el-card>
                <VueEcharts :option="option" style="height: 300px"></VueEcharts>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card>
                <VueEcharts :option="option2" style="height: 300px"></VueEcharts>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row>
        <el-col :span="24" style="margin-bottom: 20px">
          <div class="diagramTitle">
            <div class="diagramTitle" style="margin-right: 20px">
              <span style="font-size: 20px; margin-right: 10px">员工姓名:</span>
              <el-input v-model="stffName" placeholder="请输入姓名" style="width: 150px"></el-input>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <span style="font-size: 20px; margin-right: 10px">所属打样间:</span>
              <el-select v-model="theifDyj" clearable placeholder="请选择打样间" style="width: 200px">
                <el-option v-for="item in dyjSelect" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <span style="font-size: 20px; margin-right: 10px">样品类型:</span>
              <el-select v-model="sampleType" clearable placeholder="请选择样品类型" style="width: 200px">
                <el-option v-for="item in sampleTypeSelect" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button round type="primary" class="buttonText" size="mini" @click="changeModule(module)">查询</el-button>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button round type="primary" class="buttonText" size="mini" @click="exportToExcel">导出Excel</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <open :tableData="tableData" :allTableData="allTableData" v-if="module === 'open'"></open>
          <stpr :tableData="tableData" :allTableData="allTableData" v-if="module === 'stpr'"></stpr>
          <comp :tableData="tableData" :allTableData="allTableData" v-if="module === 'comp'"></comp>
          <lase :tableData="tableData" :allTableData="allTableData" v-if="module === 'lase'"></lase>
          <mase :tableData="tableData" :allTableData="allTableData" v-if="module === 'mase'"></mase>
          <embr :tableData="tableData" :allTableData="allTableData" v-if="module === 'embr'"></embr>
          <emcd :tableData="tableData" :allTableData="allTableData" v-if="module === 'emcd'"></emcd>
          <manu :tableData="tableData" :allTableData="allTableData" v-if="module === 'manu'"></manu>
          <stam :tableData="tableData" :allTableData="allTableData" v-if="module === 'stam'"></stam>
          <put :tableData="tableData" :allTableData="allTableData" v-if="module === 'put'"></put>
        </el-col>
        <el-col :span="24">
          <el-pagination
            @current-change="handleCurrentChange"
            :hide-on-single-page="true"
            :current-page="currentPage"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="this.allTableData.length"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Open from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Open';
import { get } from '@api/request';
import { dataStaticsAPI } from '@api/modules/stat';
import Stpr from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Stpr';
import Comp from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Comp';
import Lase from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Lase';
import Mase from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Mase';
import Embr from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Embr';
import Emcd from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Emcd';
import Manu from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Manu';
import Stam from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Stam';
import Put from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Put';
import htmlToExcel from '@/plugins/htmlToExcel';
import helper from '@assets/js/helper';
import { BigNumber } from 'bignumber.js';

export default {
  name: 'ProductValueYieldStatistics',
  components: { Open, Stpr, Comp, Lase, Mase, Embr, Emcd, Manu, Stam, Put },
  data() {
    return {
      allTableData: [],
      currentPage: 1,
      loading: false,
      daytime: '',
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        series: [
          {
            name: '总数',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: '款数',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          }
        ]
      },
      option2: {
        title: {
          text: '产值金额',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '金额',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      stffName: '',
      theifDyj: '',
      dyjSelect: [
        {
          value: 2,
          label: '上海'
        },
        {
          value: 17,
          label: '扬州'
        }
      ],
      sampleType: '',
      sampleTypeSelect: [
        {
          value: 1,
          label: '新款'
        },
        {
          value: 2,
          label: '老款'
        },
        {
          value: 3,
          label: '修改款'
        }
      ],
      tableData: [],
      startTime: '',
      endTime: '',
      module: 'open'
    };
  },
  watch: {
    // 监听时间范围变化 加载数据
    daytime(newValue, oldValue) {
      let { startTime, endTime } = this.helper.getTime(newValue);
      this.startTime = startTime;
      this.endTime = endTime;
      this.getProcess();
    }
  },
  mounted() {
    this.changeModule('open');
  },
  methods: {
    handleCurrentChange(val) {
      let endIndex = val === 1 ? 10 : val * 10;
      let startIndex = val === 1 ? 0 : (val - 1) * 10;
      let length = this.allTableData.length;
      if (length < endIndex) {
        endIndex = 999;
      }
      this.tableData = this.deepCopy(this.allTableData).slice(startIndex, endIndex);
      let list = this.deepCopy(this.tableData);
      this.reRenderDiagram(list);
      this.currentPage = val;
    },
    // 导出excel
    exportToExcel() {
      htmlToExcel.getExcel('#exportTableAll', this.module + helper.toTimeDay(new Date().getTime() / 1000));
    },
    // 模块选择变动加载
    changeModule(module) {
      this.loading = true;
      this.module = module;
      if (module === 'put') {
        this.getPutStorage();
      } else {
        this.getProcess();
      }
    },
    // 选择指定时间范围 加载数据
    timeScope(val) {
      this.loading = true;
      if (val === 0) {
        this.startTime = '';
        this.endTime = '';
        if (this.module === 'put') {
          this.getPutStorage();
        } else {
          this.getProcess();
        }
        return;
      }
      let date = new Date(new Date().toLocaleDateString()).getTime() / 1000;
      this.startTime = date - val * 3600 * 24;
      this.endTime = date;
      if (module === 'put') {
        this.getPutStorage();
      } else {
        this.getProcess();
      }
    },
    // 获取入库数据
    getPutStorage() {
      get(dataStaticsAPI.getPutStorage, {
        region: this.theifDyj,
        startTime: this.startTime,
        endTime: this.endTime,
        stffName: this.stffName
      }).then(({ data }) => {
        if (data.code === 0) {
          this.allTableData = this.deepCopy(data.data);
          let length = this.allTableData.length <= 9 ? this.allTableData.length : 9;
          this.tableData = this.allTableData.slice(0, length + 1);
          let list = this.deepCopy(this.tableData);
          this.reRenderDiagram(list);
          this.currentPage = 1;
        }
      });
    },
    // 重新渲染图表
    reRenderDiagram(list) {
      let yAxisData = [];
      let stffName = [];
      let requNum = [];
      let nbsp = [];
      if (this.module === 'put') {
        list.forEach(item => {
          nbsp.push({
            value: item.mtrl_sal,
            name: item.stff_name
          });
          yAxisData.push(item.stff_name);
          stffName.push(item.mtrl_num);
          requNum.push(item.mtrl_value);
        });
        // 更新图表
        this.option.yAxis.data = yAxisData; // 员工姓名
        this.option.series[0].data = stffName; // 总数
        this.option.series[1].data = requNum; // 款数
        this.option2.series[0].data = nbsp; // 饼图
        this.loading = false;
      } else {
        let map = new Map();
        switch (this.module) {
          case 'open':
            list.forEach(item => {
              if (map.has(item.open.stff_name)) {
                let tempOpen = map.get(item.open.stff_name);
                tempOpen.sumValue = item.open_value + tempOpen.open_value;
                tempOpen.requ_num = item.requ_num + tempOpen.requ_num;
                tempOpen.open.rfid_open_phr = tempOpen.open.rfid_open_phr + item.open.rfid_open_phr;
                map.set(item.open.stff_name, tempOpen);
              } else {
                item.sumValue = item.open_value;
                map.set(item.open.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.sumValue,
                name: item.open.stff_name
              });
              yAxisData.push(item.open.stff_name);
              stffName.push(item.open.rfid_open_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'stpr':
            list.forEach(item => {
              if (map.has(item.stpr.stff_name)) {
                let stpr = map.get(item.stpr.stff_name);
                stpr.requ_num = item.requ_num + stpr.requ_num;
                stpr.stpr.rfid_stpr_phr = stpr.stpr.rfid_stpr_phr + item.stpr.rfid_stpr_phr;
                map.set(item.stpr.stff_name, stpr);
              } else {
                item.sumValue = item.requ_num;
                map.set(item.stpr.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.requ_num,
                name: item.stpr.stff_name
              });
              yAxisData.push(item.stpr.stff_name);
              stffName.push(item.stpr.rfid_stpr_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'comp':
            list.forEach(item => {
              if (map.has(item.comp.stff_name)) {
                let tempcomp = map.get(item.comp.stff_name);
                tempcomp.requ_num = item.requ_num + tempcomp.requ_num;
                let bigNumber = new BigNumber(tempcomp.comp.rfid_comp_phr);
                bigNumber = bigNumber.plus(item.comp.rfid_comp_phr).toNumber();
                tempcomp.comp.rfid_comp_phr = bigNumber;
                map.set(item.comp.stff_name, tempcomp);
              } else {
                map.set(item.comp.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.requ_num,
                name: item.comp.stff_name
              });
              yAxisData.push(item.comp.stff_name);
              stffName.push(item.comp.rfid_comp_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'lase':
            list.forEach(item => {
              if (map.has(item.lase.stff_name)) {
                let templase = map.get(item.lase.stff_name);
                templase.sumValue = item.lase_value + templase.lase_value;
                templase.requ_num = item.requ_num + templase.requ_num;
                templase.lase.rfid_lase_phr = templase.lase.rfid_lase_phr + item.lase.rfid_lase_phr;
                map.set(item.lase.stff_name, templase);
              } else {
                item.sumValue = item.lase_value;
                map.set(item.lase.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.lase_value,
                name: item.lase.stff_name
              });
              yAxisData.push(item.lase.stff_name);
              stffName.push(item.lase.rfid_lase_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'mase':
            list.forEach(item => {
              if (map.has(item.mase.stff_name)) {
                let tempmase = map.get(item.mase.stff_name);
                tempmase.sumValue = item.mase_value + tempmase.mase_value;
                tempmase.requ_num = item.requ_num + tempmase.requ_num;
                tempmase.mase.rfid_sew_slice = tempmase.mase.rfid_sew_slice + item.mase.rfid_sew_slice;
                map.set(item.mase.stff_name, tempmase);
              } else {
                item.sumValue = item.mase_value;
                map.set(item.mase.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.mase_value,
                name: item.mase.stff_name
              });
              yAxisData.push(item.mase.stff_name);
              stffName.push(item.mase.rfid_sew_slice);
              requNum.push(item.requ_num);
            });
            break;
          case 'embr':
            list.forEach(item => {
              // 是否重复key
              if (map.has(item.embr.stff_name)) {
                let embr = map.get(item.embr.stff_name);
                embr.sumValue = item.embroider_value + item.patch_value_sal + embr.embroider_value + embr.patch_value_sal;
                embr.requ_num = item.requ_num + embr.requ_num;
                embr.embr.rfid_embr_phr = embr.embr.rfid_embr_phr + item.embr.rfid_embr_phr;
                map.set(item.embr.stff_name, embr);
              } else {
                item.sumValue = item.embroider_value + item.patch_value_sal;
                map.set(item.embr.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.sumValue,
                name: item.embr.stff_name
              });
              yAxisData.push(item.embr.stff_name);
              stffName.push(item.embr.rfid_embr_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'emcd':
            list.forEach(item => {
              if (map.has(item.emcd.stff_name)) {
                let tempemcd = map.get(item.emcd.stff_name);
                tempemcd.sumValue = item.new_card_value + tempemcd.new_card_value + item.modify_card_value + tempemcd.modify_card_value;
                tempemcd.requ_num = item.requ_num + tempemcd.requ_num;
                tempemcd.emcd.rfid_emcd_phr = tempemcd.emcd.rfid_emcd_phr + item.emcd.rfid_emcd_phr;
                map.set(item.emcd.stff_name, tempemcd);
              } else {
                item.sumValue = item.new_card_value + item.modify_card_value;
                map.set(item.emcd.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.sumValue,
                name: item.emcd.stff_name
              });
              yAxisData.push(item.emcd.stff_name);
              stffName.push(item.emcd.rfid_emcd_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'manu':
            list.forEach(item => {
              if (map.has(item.manu.stff_name)) {
                let tempmanu = map.get(item.manu.stff_name);
                tempmanu.sumValue =
                  tempmanu.manuAValue +
                  tempmanu.manuBValue +
                  tempmanu.manuCValue +
                  tempmanu.manuDValue +
                  item.manuAValue +
                  item.manuBValue +
                  item.manuCValue +
                  item.manuDValue;
                tempmanu.requ_num =
                  tempmanu.manuStyleA +
                  tempmanu.manuStyleB +
                  tempmanu.manuStyleC +
                  tempmanu.manuStyleD +
                  item.manuStyleA +
                  item.manuStyleB +
                  item.manuStyleC +
                  item.manuStyleD;
                tempmanu.requ_num_val =
                  tempmanu.manuStyleAVal +
                  tempmanu.manuStyleBVal +
                  tempmanu.manuStyleCVal +
                  tempmanu.manuStyleDVal +
                  item.manuStyleAVal +
                  item.manuStyleBVal +
                  item.manuStyleCVal +
                  item.manuStyleDVal;
                map.set(item.manu.stff_name, tempmanu);
              } else {
                item.sumValue = item.manuAValue + item.manuBValue + item.manuCValue + item.manuDValue;
                item.requ_num = item.manuStyleA + item.manuStyleB + item.manuStyleC + item.manuStyleD;
                item.requ_num_val = item.manuStyleAVal + item.manuStyleBVal + item.manuStyleCVal + item.manuStyleDVal;
                map.set(item.manu.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.sumValue,
                name: item.manu.stff_name
              });
              yAxisData.push(item.manu.stff_name);
              stffName.push(item.requ_num_val);
              requNum.push(item.requ_num);
            });
            break;
          case 'stam':
            list.forEach(item => {
              if (map.has(item.stam.stff_name)) {
                let tempstam = map.get(item.stam.stff_name);
                tempstam.sumValue = item.stam_value + tempstam.stam_value;
                tempstam.requ_num = item.requ_num + tempstam.requ_num;
                tempstam.stam.rfid_stam_phr = tempstam.stam.rfid_stam_phr + item.stam.rfid_stam_phr;
                map.set(item.stam.stff_name, tempstam);
              } else {
                item.sumValue = item.stam_value;
                map.set(item.stam.stff_name, item);
              }
            });
            map.forEach(item => {
              nbsp.push({
                value: item.sumValue,
                name: item.stam.stff_name
              });
              yAxisData.push(item.stam.stff_name);
              stffName.push(item.stam.rfid_stam_phr);
              requNum.push(item.requ_num);
            });
            break;
          case 'buyer':
            nbsp.push({
              value: 0,
              name: ''
            });
            yAxisData.push('');
            stffName.push('');
            requNum.push('');
        }
        // 更新图表
        this.option.yAxis.data = yAxisData; // 员工姓名
        this.option.series[0].data = stffName; // 总数
        this.option.series[1].data = requNum; // 款数
        this.option2.series[0].data = nbsp; // 饼图
        this.loading = false;
      }
    },
    // 深拷贝
    deepCopy(obj) {
      let oldObj = JSON.stringify(obj);
      return JSON.parse(oldObj);
    },
    // 获取岗位数据
    getProcess() {
      get(dataStaticsAPI.getProcess, {
        startTime: this.startTime,
        endTime: this.endTime,
        module: this.module,
        smplStffName: this.stffName,
        beloDeptId: this.theifDyj,
        smplType: this.sampleType
      }).then(({ data }) => {
        if (data.code === 0) {
          this.allTableData = this.deepCopy(data.data);
          let length = this.allTableData.length <= 10 ? this.allTableData.length : 10;
          this.tableData = this.allTableData.slice(0, length);
          // 此处需要深拷贝
          let list = this.deepCopy(this.tableData);
          this.reRenderDiagram(list);
        }
      });
    }
  }
};
</script>

<style scoped>
.jobsBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  font-size: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.diagramTitle {
  display: flex;
  align-items: center;
}

.dateSelect {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.buttonText {
  font-size: 18px;
}
</style>
